import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Listbox } from "@headlessui/react";

import { ReactComponent as BackArrow } from "../assets/icons/arrow-left.svg";
import { ReactComponent as BasicDetailsImg } from "../assets/icons/basic_details.svg";
import { ReactComponent as Chevron } from "../assets/icons/arrow-down.svg";

const BasicDetails = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [gender, setGender] = useState(state?.gender ?? "male");
  const [dob, setDOB] = useState(state?.dob ?? "");
  const [height, setHeight] = useState(state?.height?.toString?.() ?? "");
  const [weight, setWeight] = useState(state?.weight?.toString?.() ?? "");
  const [error, setError] = useState("");

  const checkForm = (e) => {
    e.preventDefault();
    try {
      if (dob?.length > 0) {
        const age = Math.abs(
          new Date(Date.now() - new Date(dob).getTime()).getUTCFullYear() - 1970
        );
        if (age < 18 || age > 110) throw new Error(t("AgeError"));
      } else throw new Error(t("InvalidDOB"));

      if (!(gender?.length > 0 && /^(male|female)$/.test(gender)))
        throw new Error(t("InvalidGender"));

      if (height?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(height)) {
        if (height < 30 || height > 250) throw new Error(t("HeightError"));
      } else throw new Error(t("InvalidHeight"));

      if (weight?.length > 0 && /^\d{1,3}(\.\d{1,2})?$/.test(weight)) {
        if (weight < 20 || weight > 220) throw new Error(t("WeightError"));
      } else throw new Error(t("InvalidWeight"));

      const edited = { dob, gender, height, weight };

      if (
        state?.scan_type?.length > 0 &&
        /^(face|finger)$/.test(state.scan_type)
      ) {
        if (
          state?.posture?.length > 0 &&
          /^(resting|standing|walking|exercising)$/.test(state.posture)
        )
          navigate("/scan-instructions", { state: { ...state, ...edited } });
        else navigate("/select-posture", { state: { ...state, ...edited } });
      } else navigate("/select-scan-type", { state: { ...state, ...edited } });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section className="min-h-screen px-5 pt-16 pb-32">
      <div className="fixed top-0 left-0 right-0 flex items-center gap-2 px-4 py-3 border-b border-[#D6D9DA] bg-white text-[#0D212C]">
        <button
          className="shrink-0 h-6 w-6"
          type="button"
          onClick={() => {
            window.parent?.postMessage?.("go-back:::basic-details", "*");
            window.ReactNativeWebView?.postMessage?.("go-back:::basic-details");
          }}
        >
          <BackArrow className="h-full w-full" />
        </button>
        <h2 className="text-sm font-semibold">{t("Wellness")}</h2>
      </div>

      <BasicDetailsImg className="mt-3 mx-auto max-w-full" />

      <div
        className="mt-6 flex flex-row items-stretch justify-between overflow-hidden rounded-lg shadow-sm"
        dir="ltr"
      >
        <button
          dir="ltr"
          type="button"
          className={`flex-1 px-5 py-2.5 outline-none ${
            i18n.resolvedLanguage === "en"
              ? "bg-[#F4FFFD] text-[#3ABBC7]"
              : "bg-white text-[#0D212C]"
          } text-sm leading-none font-medium`}
          onClick={() => i18n.changeLanguage("en")}
        >
          English
        </button>
        <button
          dir="rtl"
          type="button"
          className={`flex-1 px-5 py-2.5 outline-none ${
            i18n.resolvedLanguage === "ar"
              ? "bg-[#F4FFFD] text-[#3ABBC7]"
              : "bg-white text-[#0D212C]"
          } text-sm leading-none font-medium`}
          onClick={() => i18n.changeLanguage("ar")}
        >
          العربية
        </button>
      </div>

      <h3 className="mt-4 text-[#0D212C] text-base font-semibold">
        {t("BasicDetails")}
      </h3>

      <form className="my-4" onSubmit={checkForm}>
        <div className="mb-3">
          <label htmlFor="dob" className="block text-[#3E4E56] text-xxs mx-4">
            {t("DOB")} <span className="text-[#F05252]">*</span>
          </label>
          <input
            className="block w-full mt-2 px-4 py-3.5 outline-none rounded-lg shadow-sm bg-white text-[#0D212C] text-sm"
            id="dob"
            name="dob"
            type="date"
            max={new Date().setFullYear(new Date().getFullYear() - 18)}
            min={new Date().setFullYear(new Date().getFullYear() - 110)}
            autoComplete="off"
            value={dob}
            onChange={(e) => setDOB(e.target.value)}
          />
        </div>
        <div className="mb-3 grid grid-cols-2 gap-2.5">
          <div>
            <label
              htmlFor="height"
              className="block text-[#3E4E56] text-xxs mx-4"
            >
              {t("Height")} <span className="text-[#F05252]">*</span>
            </label>
            <input
              className="block w-full mt-2 px-4 py-3.5 outline-none rounded-lg shadow-sm bg-white text-[#0D212C] text-sm"
              id="height"
              name="height"
              type="text"
              inputMode="numeric"
              autoComplete="off"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block text-[#3E4E56] text-xxs mx-4"
            >
              {t("Weight")} <span className="text-[#F05252]">*</span>
            </label>
            <input
              className="block w-full mt-2 px-4 py-3.5 outline-none rounded-lg shadow-sm bg-white text-[#0D212C] text-sm"
              id="weight"
              name="weight"
              type="text"
              inputMode="numeric"
              autoComplete="off"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
        </div>
        <Listbox
          as="div"
          className="relative"
          value={gender}
          onChange={setGender}
        >
          <Listbox.Label className="block text-[#3E4E56] text-xxs mx-4">
            {t("Gender")} <span className="text-[#F05252]">*</span>
          </Listbox.Label>
          <Listbox.Button className="relative block w-full mt-2 px-4 py-3.5 outline-none rounded-lg shadow-sm bg-white text-[#0D212C] text-sm ltr:text-left rtl:text-right capitalize">
            {t(gender)}
            <Chevron className="absolute ltr:right-3 rtl:left-3 inset-y-4" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-50 w-full mt-1 py-1 rounded-lg drop-shadow bg-white">
            {["male", "female"].map((g) => (
              <Listbox.Option
                key={`gender-${g}`}
                value={g}
                className={({ selected }) =>
                  `px-4 py-2 ${
                    selected
                      ? "text-[#3ABBC7] bg-[#F4FFFD]"
                      : "text-[#0D212C] active:bg-[#F4FFFD]"
                  } text-xs capitalize`
                }
              >
                {t(g)}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
        <div className="fixed bottom-0 left-0 right-0 h-[7.5rem] p-5 rounded-t-[20px] shadow-drop bg-white">
          <button
            className="block w-full px-4 py-3.5 rounded-xl bg-[#3ABBC7] text-white text-sm font-bold"
            type="submit"
          >
            {t("Next")}
          </button>
          {error.length > 0 && (
            <p className="mt-2.5 px-3 py-1.5 rounded-lg flex items-center gap-2 bg-[#F05252]/15 text-[#F05252]">
              <svg
                className="h-4 w-4 flex-shrink-0"
                viewBox="0 0 48 48"
                fill="currentColor"
              >
                <path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z" />
              </svg>
              <span className="text-xs font-light">{error}</span>
            </p>
          )}
        </div>
      </form>
    </section>
  );
};

export default BasicDetails;
